import { Link } from "gatsby"
import React from "react"
import Button from "../components/Button"
import Layout from "../components/Layout"

export default function NotFoundPage() {
  return (
    <Layout bigHeader={true}>
      <title>Introuvable</title>
      <section
        style={{
          textAlign: "center",
          padding: "100px 0",
        }}
      >
        <h1>Page introuvable</h1>
        <p style={{ fontSize: "1.2em" }}>
          Désolé, nous n'avons pas trouvé ce que vous cherchez. 😔
        </p>
        <Link to="/">
          <Button big>Retour à l'accueil</Button>
        </Link>
      </section>
    </Layout>
  )
}
